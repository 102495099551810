import Vue from "vue";

import auth from "@websanova/vue-auth/dist/v2/vue-auth.common.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";
import * as Sentry from "@sentry/vue";

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    http: driverHttpAxios,
    router: driverRouterVueRouter,  
    auth: {
      request: (req, token) => {
        driverHttpAxios.setHeaders.call(this, req, {
          Authorization: "Bearer " + token,
        });
      },
      response: (res) => {
        if (res.status === 200) {
					if (res.data) {
						if (res.data.two_factor_auth_required) return res.data.token;

						if (res.data.access_token) return res.data.access_token;

						if (res.data.refresh_token) return res.data.refresh_token;
					}
				}
      },
    },
  },
  options: {
    parseUserData: data => {
      const user = data.user || data;
      const { id, email, surname, name } = user;
      Sentry.setUser({ id, email, surname, name });
      return user;
    },
    tokenDefaultName: "access_token",
    notFoundRedirect: { name: "Home" },
    refreshData: { enabled: false },
    rolesKey: "services",
    logoutData: {
      makeRequest: true,
    }
  },
});