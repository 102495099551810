<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const defaultLayout = "no-nav";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
    loggedIn() {
      return this.$auth.check();
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  font-family: Visby, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d3d2cb !important;
}
.card,
.b-overlay div {
  border-radius: 10px !important;
  border: none !important;
}

/* Extra Bold Visby Round font */
@font-face {
  font-family: "VisbyBold";
  src: local("VisbyBold"),
    url(assets/fonts/VisbyRoundCF-Bold.woff2) format("woff2");
}

/* Regular Visby Round font */
@font-face {
  font-family: "VisbyRegular";
  src: local("VisbyRegular"),
    url(assets/fonts/VisbyRoundCF-Regular.woff2) format("woff2");
}
</style>
