import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
axios.interceptors.response.use(res => {
  Object.assign(res, res.data);
  return res;
});

Vue.use(VueAxios, axios);
let baseDomain = 'https://api.lulaloop.co.za/api'
if (process.env.VUE_APP_LULA_API_URL) {
  baseDomain = process.env.VUE_APP_LULA_API_URL
}
Vue.axios.defaults.baseURL = `${baseDomain}`;
// eslint-disable-next-line dot-notation
Vue.axios.defaults.headers.common["source"] = "intranet";
