<template>
  <b-container>
    <b-navbar class="text-center">
      <b-navbar-brand href="#">
        <img
          src="@/assets/lula-red.png"
          class="img-fluid"
          width="120"
          alt="Lula Intranet"
        />
        <h6 class="font-weight-bold"><small>Intranet</small></h6>
      </b-navbar-brand>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <b-nav-item-dropdown :text="fullName" toggle-class="text-dark ">
          <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </b-navbar>
    <router-view />
  </b-container>
</template>

<script>
export default {
  name: "Default",
  data() {
    return {};
  },
  computed: {
    fullName() {
      return this.$auth.check()
        ? this.$auth.user().name + " " + this.$auth.user().surname
        : "";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
