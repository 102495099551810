<template>
  <b-container fluid>
    <b-row id="default">
      <b-col class="bg-white d-none d-lg-block p-5">
        <b-container fluid class="h-100">
          <b-row class="h-100 justify-content-between">
            <b-col style="display: contents">
              <div style="width: 120px">
                <img class="img-fluid" src="@/assets/logo.png" />
              </div>
              <span>
                <h2 class="font-weight-bold">
                  Work Ready.
                  <br />
                  Home Safe
                </h2>
              </span>
            </b-col>
            <b-col cols="12">
              <h1>
                Lula Intranet. Systems, Services, Tools and Statistics.
              </h1>
              <p>
                One-Stop-Shop for all things company-related!
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col style="display: grid" class="align-content-around">
        <slot />
        <Footer />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Footer from "@/components/layouts/Footer";

export default {
  components: {
    Footer
  }
};
</script>

<style lang="scss" scoped>
#default {
  min-height: 100vh;
}
h1 {
  font-family: "VisbyRegular";
  font-size: 55px;
  font-weight: 600;
  color: #34495e;
}

h2 {
  font-family: "VisbyBold";
  font-size: 1.9rem;
  text-align: right;
}

p {
  font-family: "VisbyRegular";
  font-size: 32px;
}
</style>
