<template>
  <footer>
    <div class="text-center small">
      <div>
        Copyright © {{ year }}. Lula Loop (PTY) Ltd. All rights reserved.
      </div>
      <b-link
        class="font-weight-bold text-dark"
        href="http://www.lulaloop.co.za/privacy-policy"
        >Privacy
      </b-link>
      <span>and </span>
      <b-link
        class="font-weight-bold text-dark"
        href="http://www.lulaloop.co.za/terms-of-use"
        >Terms of Use
      </b-link>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    }
  }
};
</script>
