import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const intranetAuthConfig = {
  auth: {
    roles: ["dashboard"],
  },
  layout: "default"
};

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: "auth",
      auth: false,
    },
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      layout: "no-nav",
      auth: false
    },
    component: () => import("@/views/Auth/ForgotPassword.vue"),
  },
  {
    path: "/home",
    name: "Home",
    meta: intranetAuthConfig,
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/qa",
    name: "QA",
    meta: intranetAuthConfig,
    component: () => import("@/views/QA.vue"),
  },
  {
    path: "/history",
    name: "QAHistory",
    meta: intranetAuthConfig,
    component: () => import("@/views/QAHistory.vue"),
  },
  {
    path: "/stats",
    name: "Stats",
    meta: intranetAuthConfig,
    component: () => import("@/views/Stats.vue"),
  },
  {
    path: "/changelogs",
    name: "Changelog",
    meta: intranetAuthConfig,
    component: () => import("@/views/ChangeLog.vue"),
  }
];

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default Vue.router;
