import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from './plugins'
import './registerServiceWorker'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import NoNav from "./layouts/NoNav.vue";
import Auth from "./layouts/Auth.vue";
import Default from "./layouts/Default.vue";

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.component("no-nav-layout", NoNav);
Vue.component("auth-layout", Auth);
Vue.component("default-layout", Default);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  plugins,
  render: h => h(App)
}).$mount('#app')
