import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },

    refresh(data) {
      return Vue.auth.refresh(data);
    },

    async login(ctx, data) {
      data = data || {};

      return await Vue.auth.login({
        url: "auth/login",
        data: data.body,
        staySignedIn: data.staySignedIn,
        fetchUser: false,
      });
    },

    logout() {
      return Vue.auth.logout();
    },
  },

  getters: {
    user() {
      return Vue.auth.user();
    }
  }
};